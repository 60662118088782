.formHelp {
  font-size: 12px;
  color: rgb(244, 0, 0);
  margin-top: 5px;
}

ion-slides {
  height: 100%;
}

.hra-slide {
  height: 100%;
}

.boarderTest
{
  border: 1px solid red;
}

.continue-button
{
  margin-bottom: 100px;
  --margin-bottom: 100px;

}

.hraPadding
{
  padding-bottom: 50px;
}

.boarderTestOrange
{
  border: 1px solid orange;
}

.boarderTestGreen
{
  border: 1px solid green;
}

.boarderTestBlue
{
  border: 1px solid blue;
}


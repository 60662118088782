.verify-title {
    font-size: 1.5rem;
    font-weight: 600;
}

.verify-cont {
    height: 100%;
}

.verify-cont-inner {
    margin: 0;
    position: absolute;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -80%);
}

.verify-popup {
    background-color: #456673;
    --border-radius: 10px;
    --max-height: 60%;
    

}

.noSubContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.noSubIcon{
    font-size: 100px;
}


.main-logo {
  width: 200px;
  height: auto;
  padding-top: 5vh;
}

.container {
  /* Center vertically and horizontally */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.terms {
  text-decoration: underline;
}

.terms:hover {
  cursor: pointer;
}

.form-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 50vw;
  padding: 20px;
}

.empty-message {
  display: none;
  color: red;
}

.submit-message {
  display: none;
}

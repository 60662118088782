.face-scan-icon {
  font-size: 90px;
}

.button-style {
  display: block;
  width: auto;
  height: auto;
  background: #456673;
  padding: 7px;
  text-align: center;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  line-height: 25px;
  text-decoration: none;
  cursor: pointer;
}

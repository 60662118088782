.koroQuestionAnswerHeaderItem{
    --background: white;
}

.koroQuestionAnswerAvatar{
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.koroQuestionAnswerTime {
    font-size: 12px;
    color: #78797a;
}

.expert{
    color: var(--ion-color-secondary);
}

.replyLink{
    color: var(--ion-color-primary) !important;
    font-size: 12px !important;
    padding-top: 4px !important;
}

.replies{
    font-size: 12px;
    color: #78797a;
}

.boarderTesting{
    border: 1px solid #b60505;
}
.session {
  --border-radius: 10px;
  margin: 5px;
}

.journal-square {
  width: 180px;
  height: 120px;
  border-radius: 10px;
  color: #ffffff;
  margin: auto;
  padding-top: 15px;
  padding-bottom: 5px;
  font-size: 15px;
  font-weight: 500;
}

.meal {
  background-color: #243841;
}

.activity {
  background-color: #93767a;
}

.metrics {
  background-color: #8fa3ab;
}

.mood {
  background-color: #456673;
}

.log-icon {
  font-size: 60px;
  color: #ffffff;
}

.remove-bg {
  --background : transparent;
}



.new-section-title {
  font-size: 15px;
  color: #243841;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.new-section {
  --background: #f0f0f0;
}


.viewAll {
  font-size: 12px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #243841;
}

.mainPageTitle {
  color: #456673;
  letter-spacing: 1px;
  text-transform: uppercase;

  font-weight: 400;
}

.mainHeaderBackground{
  --background :  #456673;
}

.mainPageTitleTEst {
  color: #ffffff;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: #456673;

  font-weight: 400;
}

.headerButtonColors {
  color: white;
}


.center-home {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
}

.white {
  color: white;
}





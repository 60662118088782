.welcomeKoroTitle {
    font-size: 1.5rem;
}

.accountSetupMoreInfo {
    color: #78797a;
    margin-bottom: 10px;
    text-align: center;
}

.subTitle {
    color: #78797a;
    padding-left: 0px;
}

.ionPaddingTopMedium {
    padding-top: 40px;
}

.ionPaddingTopSmall {
    padding-top: 10px;
}

.inputItemForm {
    border: 1px solid #3e3e3e4e;
    border-radius: 20px;
    padding-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.select {
    padding: 10px;
    padding-right: 20px;
    padding-left: 20px

}

.koroOnboardingContainer
{
    max-width: 600px;
}

.ionSelect
{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
}
.koroQuestionHeaderImgWrapper {
    width: 90%;
    height: 150px;
    margin: 0 auto;
    border-radius: 10px;
    object-fit: cover;
}

.koroQuestionHeaderImg {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;

}

.koroQuestionBodyCard {
    font-size: 0.9rem;
    color: var(--ion-color-dark);
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

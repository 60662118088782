.date-title {
  font-size: 100px;
  margin-bottom: 1rem;
}

.widget-padding {
  padding: 20px;
}

.visitTypeText {
  color: rgb(139, 139, 139);
}


.visitType-item {
  --border-radius: 10px;
  margin: 15px;
  border-radius: 10px;
  /* offset-x | offset-y | blur-radius | spread-radius | color */
  box-shadow: 0 1px 1px 2px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
}

.visitType-title {
  font-weight: 600;
}

.visitTypeIcon {
  background-color: #8fa3ab;
  border-radius: 50px;
  padding: 5px;
  font-size: 35px;
  stroke: #456673;
}